import {baseUrl} from '../utils/helper';
import axios from './axios-interceptor';

class UserService {
  getUserById(id){
    return axios.get(`${baseUrl}/user/get/${id}`)
  }
  updateProfile(data){
    return axios.post(`${baseUrl}/profile/confirm`,data)
  }
  requestOTP(data){
    return axios.post(`${baseUrl}/register/request`,data)
  }
  confirmOTP(data){
    return axios.post(`${baseUrl}/register/confirm/otp`,data)
  }
  confirmRegister(data){
    return axios.post(`${baseUrl}/register/confirm`,data)
  }
  confirmRegister(data){
    return axios.post(`${baseUrl}/register/confirm`,data)
  }
}

export default new UserService();
