<template>
    <div>
      <a-select
        v-model="selectedId"
        show-search
        placeholder="Choose a province"
        @change="onSelected"
        clear-icon
        allow-clear
      >
        <a-select-option  v-for="province in provinces" :key="province.id" :value="province.id">
          {{ province.name }}
        </a-select-option>
      </a-select>
    </div>
  </template>
  
  <script>
  import dataService from "../../services/data.service";
  
  export default {
    props: {
      provinceId: String,
    },
    data() {
      return {
        provinces: [],
        selected: null,
        loading: false,
      };
    },
    created() {
        this.getProvinces()
    },
    computed: {
      selectedId: {
        get() {
          return this.selected ? this.selected.id : null;
        },
        set(value) {
          const selectedProvince = this.provinces.find(province => province.id === value);
          return selectedProvince? this.selected = selectedProvince: this.selected = null;
        }
      }
    },
  
    methods: {
      async getProvinces() {
        try {
          const res = await dataService.getProvinces();
          this.provinces = res.data.map(item => ({
            id: item.id,
            name: item.name.us,
          }));
          if (this.provinceId) {
          const provinceIdNumber = Number(this.provinceId);
          const selectedProvince = this.provinces.find(province => province.id === provinceIdNumber);
          if (selectedProvince) {
            this.selected = selectedProvince;
          }
        }
        } catch (err) {
          console.error(err);
        }
      },
      onSelected(id) {
        this.$emit('change', id)
      },
    },
  };
  </script>
  