<template>
    <div class="clearfix">
      <div v-if="photo">
          <img @click="getPreviewPhoto(photo,text1, text2)" alt="" 
          :style="{ maxWidth: width ? `${width}px` : '80%', maxHeight: height ? `${height}px`:'150px'}" 
          :src="getPhoto(photo,text1, text2)" />
    </div>
      <a-modal width='300' :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </template>
  <script>
  import { parseImageUrl} from '../../utils/helper'
  export default {
    name: "WidgetPreviewPhoto",
    props: {
    photo: {
      type: String,
      default: null,
    },
    text1: {
      type: String,
      default: null,
    },
    text2: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
    data() {
      return {
        previewVisible: false,
        previewImage: "",
      };
    },
    methods: {
      handleCancel() {
        this.previewVisible = false;
        this.previewImage = ';'
      },
      getPhoto(photo, image1, image2) {
      try {
       const imagePhoto = JSON.parse(photo || '{}');
       if ((imagePhoto[image1] && imagePhoto[image1] !='') || (imagePhoto[image2] && imagePhoto[image2] != '')){
      return parseImageUrl(imagePhoto[image1]? imagePhoto[image1]: imagePhoto[image2])
      }
        return null;
      } catch (error) {
        console.error("Error parsing photo:", error);
        return require(`@/assets/images/no-image.png`);
      } 
    },
   async getPreviewPhoto(photo, image1, image2) {
      try {
       const imagePhoto = await JSON.parse(photo || '{}');
       if ((imagePhoto[image1] && imagePhoto[image1] !='') || (imagePhoto[image2] && imagePhoto[image2] != '')){
        this.previewImage = await parseImageUrl(imagePhoto[image1]? imagePhoto[image1]: imagePhoto[image2])
        this.previewVisible = true
      }
        return null;
      } catch (error) {
        console.error("Error parsing photo:", error);
        return require(`@/assets/images/no-image.png`);
      } 
    },
    },
  };
  </script>
  