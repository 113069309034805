<template>
  <div>
    <div v-if="isLoading">
      <Loading />
    </div>
    <!-- Header Background Image -->
    <div v-else>
      <div
        class="profile-nav-bg"
        style="background-image: url('images/background.jpeg')"
      ></div>
      <!-- / Header Background Image -->

      <!-- User Profile Card -->
      <a-card
        :bordered="false"
        class="card-profile-head"
        :bodyStyle="{ padding: 0 }"
      >
        <template #title>
          <a-row type="flex" justify="space-between" align="middle">
            <a-col class="col-info m-20">
              <a-avatar :size="100" shape="circle">
                <WidgetPreviewPhoto :width="100" :height="200" text1="photoprofile" text2="profile" :photo="userInfo.photo"/>
              </a-avatar>
              <div class="avatar-info">
                <h5 class="font-semibold m-0">
                  {{ userInfo.firstname }} {{ userInfo.lastname }}
                </h5>
                <p>{{ userInfo.dob ? formatDate(userInfo.dob) : null}}</p>
              </div>
            </a-col>
            <a-col class="m-20">
              <a-button @click="isModalVisible = true" type="primary">{{
                $t("action.EditButton")
              }}</a-button>
            </a-col>
          </a-row>
          <EditProfileModal
            :visible="isModalVisible"
            :userInfo="userInfo"
            @close="handleModalClose"
            @change="getUser"
          />
          <CardProfileInformation :userInfo="userInfo" />
        </template>
      </a-card>
      <!-- User Profile Card -->
    </div>
  </div>
</template>

<script>
import CardProfileInformation from "../components/Cards/CardProfileInformation";
import Loading from "../components/Loading/Loading.vue";
import UserService from "../services/user.service";
import { lsread } from "../utils/storage";
import EditProfileModal from "../components/Modals/EditProfileModal.vue";
import WidgetPreviewPhoto from "../components/Widgets/WidgetPreviewPhoto.vue";
import {formatDate} from '../utils/helper'
import { message} from "ant-design-vue";

export default {
  components: {
    CardProfileInformation,
    Loading,
    EditProfileModal,
    WidgetPreviewPhoto,
  },
  data() {
    return {
      userId: "",
      userInfo: "",
      isLoading: true,
      isModalVisible: false,
    };
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.isLoading = true;
      let user = await lsread("user");
      if (user) {
        this.userId = user.id;
        await UserService.getUserById(this.userId)
          .then((response) => {
            this.userInfo = response.data;
            this.isLoading = false;
          })
          .catch((err) => {
            message.error(err.message);
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleModalClose() {
    this.isModalVisible = false;
  },
    formatDate
  },
};
</script>

<style lang="scss"></style>
