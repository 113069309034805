<template>
  <!-- Profile Information Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full card-profile-information"
    :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
    :headStyle="{ paddingRight: 0 }"
  >
    <hr class="my-15" />
    <a-descriptions v-if="userInfo" :column="{ xxl: 4, xl: 3, lg: 2, md: 2, xs: 1 }">
      <a-descriptions-item :label="$t('form.phone')">
        {{ userInfo.phone }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.email')">
        {{ userInfo.email }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.gender.title')">
        {{ userInfo.gender }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.passportNumber')">
        {{ userInfo.passport }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.city')">
        {{ userInfo.city }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.purposeVisit')">
        {{ userInfo.purposeofvisit ==="TOUR"?$t("form.tour"): 
        userInfo.purposeofvisit ==="WORK30"?$t("form.work"): 
        $t("form.work30") }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('form.stateProvince')">
        {{ userInfo.province ? userInfo.province.name.us : "" }}
      </a-descriptions-item>
    </a-descriptions>

    <h6 class="mt-15">{{ $t("form.document") }}:</h6>
    <a-row :gutter="24" style="text-align: center;">
        <a-col :span="24" :md="8">
          <h6 class="my-15">{{ $t("form.passport") }}</h6>
          <WidgetPreviewPhoto text1="passport" text2="photopassport" :photo="userInfo.photo"/>
        </a-col>
        <a-col :span="24" :md="8">
          <h6 class="my-15">{{ $t("form.visa") }}</h6>
          <WidgetPreviewPhoto text1="vaccine" text2="photovaccine" :photo="userInfo.photo"/>
        </a-col>
        <a-col :span="24" :md="8">
          <h6 class="my-15">{{ $t("form.other") }}</h6>
          <WidgetPreviewPhoto text1="rtpcr" text2="photortpcr" :photo="userInfo.photo"/>
        </a-col>
      </a-row>
  </a-card>
  <!-- / Profile Information Card -->
</template>

<script>
import WidgetPreviewPhoto from "../Widgets/WidgetPreviewPhoto.vue";

export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    WidgetPreviewPhoto,
  },
};
</script>
