<template>
  <div>
    <Modal :visible="modalVisible" :dialog-style="{ top: '20px' }" :width="700" @cancel="handleModalClose"
      :title="$t('title.editProfile')">
      <template slot="footer">
        <a-button key="back" @click="handleModalClose">
          {{ $t("button.cancel") }}
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleEditProfile">
          {{ $t("button.submit") }}
        </a-button>
      </template>
      <a-form name="form">
        <a-row style="margin: 0 auto; max-width: 200px">
          <a-col class="col-info">
            <a-avatar :size="100" shape="circle">
              <WidgetPreviewPhoto :width="100" text1="photoprofile" text2="profile" :photo="userInfo.photo" />
            </a-avatar>
          </a-col>
          <a-col class="mt-10">
            <WidgetAddPhoto :uploadText="'New Profile'" @upload="onProfileUploaded" />
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-top: 20px">
          <a-col :xs="24" :md="12">
            <div class="my-10">
              <a-form-item :label="$t('form.firstName')">
                <a-input v-model="user.firstname" v-validate="'required|min:2'" type="text" class="form-control"
                  name="first_name" data-vv-as="First Name" />
              </a-form-item>
              <div v-if="submitted && errors.has('firstname')" style="color: red">
                {{ errors.first("firstname") }}
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <div class="my-10">
              <a-form-item :label="$t('form.lastName')">
                <a-input v-model="user.lastname" v-validate="'required|min:2'" type="text" class="form-control"
                  name="last_name" data-vv-as="Last Name" />
              </a-form-item>
              <div v-if="submitted && errors.has('lastname')" style="color: red">
                {{ errors.first("lastname") }}
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.phone')">
              <vue-tel-input v-model="user.phone" v-bind="vueTel.props" :v-validate="user.email?'': 'required|min:8|max:20'"
                type="text" data-vv-rules="required" data-vv-as="Phone" name="phone" style="height: 2.5rem; margin-top: 4px;">
              </vue-tel-input>
            </a-form-item>
            <div v-if="submitted && errors.has('phone')" style="color: red">
              {{ errors.first("phone") }}
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.email')">
              <a-input v-model="user.email" :v-validate="user.phone?'':'required'" type="email" class="form-control" name="email"
                data-vv-as="Email" />
            </a-form-item>
            <div v-if="submitted && errors.has('email')" style="color: red">
              {{ errors.first("email") }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.gender.title')">
              <a-select v-validate="'required'" placeholder="Select your gender" v-model="user.gender"
                data-vv-as="Gender" name="gender">
                <a-select-option value="MALE">
                  {{ $t("form.gender.male") }}
                </a-select-option>
                <a-select-option value="FEMALE">
                  {{ $t("form.gender.female") }}</a-select-option>
                <a-select-option value="OTHER">
                  {{ $t("form.gender.other") }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div style="color: red">
              {{ errors.first("gender") }}
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.birthday')">
              <a-date-picker :default-value="user.dob ? moment(user.dob) : ''" format="DD/MM/YYYY" v-model="user.dob"
                v-validate="'required'" name="birthday" data-vv-as="Date of Birth" />
            </a-form-item>
            <div style="color: red">
              {{ errors.first("birthday") }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.passport')">
              <a-input v-model="user.passport" v-validate="'required|min:5|max:20'" type="text" class="form-control"
                name="passport" data-vv-as="Passport" /></a-form-model-item>
            <div style="color: red">
              {{ errors.first("passport") }}
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.purposeVisit')">
              <a-select v-model="user.purpose" v-validate="'required'" placeholder="Select your purpose of visit"
                data-vv-as="Purpose of visit" name="purpose">
                <a-select-option value="TOUR">
                  {{ $t("form.tour") }}
                </a-select-option>
                <a-select-option value="WORK30">
                  {{ $t("form.work") }}
                </a-select-option>
                <a-select-option value="WORK">
                  {{ $t("form.work30") }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div style="color: red">
              {{ errors.first("purpose") }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.city')">
              <a-input v-model="user.city" type="text" class="form-control" /></a-form-model-item>
            <!-- <div style="color: red">
              {{ errors.first("passport") }}
            </div> -->
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.stateProvince')">
              <WidgetProvince :provinceId="String(userInfo.province? userInfo.province.id : '')"
                @change="onProvinceSelected" />
            </a-form-model-item>
            <div v-if="!user.province_id" style="color: red">
              {{ provinceSelect }}
            </div>
          </a-col>
        </a-row>

        <h4>{{ $t("form.document") }}</h4>
        <a-row :gutter="24">
          <a-col :span="24" :sm="8" class="mb-15">
            <h6 style="justify-content: center">{{ $t("form.passport") }}</h6>
            <WidgetAddPhoto :uploadText="'New Image'" @upload="onPassportUploaded" />
          </a-col>
          <a-col :span="24" :sm="8" class="mb-15">
            <h6>{{ $t("form.visa") }}</h6>
            <WidgetAddPhoto :uploadText="'New Image'" @upload="onVisaUploaded" />
          </a-col>
          <a-col :span="24" :sm="8" class="mb-15">
            <h6>{{ $t("form.other") }}</h6>
            <WidgetAddPhoto :uploadText="'New Image'" @upload="onETCUploaded" />
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24" :md="8" class="my-10">
            <WidgetPreviewPhoto text1="passport" text2="photopassport" :photo="userInfo.photo" />
          </a-col>
          <a-col :span="24" :md="8" class="my-10">
            <WidgetPreviewPhoto text1="vaccine" text2="photovaccine" :photo="userInfo.photo" />
          </a-col>
          <a-col :span="24" :md="8" class="my-10">
            <WidgetPreviewPhoto text1="rtpcr" text2="photortpcr" :photo="userInfo.photo" />
          </a-col>
        </a-row>
      </a-form>
    </Modal>
  </div>
</template>
<script>
import { Modal } from "ant-design-vue";
import moment from "moment";
import WidgetAddPhoto from "../Widgets/WidgetAddPhoto.vue";
import WidgetPreviewPhoto from "../Widgets/WidgetPreviewPhoto.vue";
import WidgetProvince from "../Widgets/WidgetProvince.vue";
import userService from "../../services/user.service";
import dataService from "../../services/data.service";

export default {
  components: {
    WidgetAddPhoto,
    WidgetPreviewPhoto,
    Modal,
    WidgetProvince,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      modalVisible: this.visible,
      submitted: false,
      loading: false,
      user: {
        id: null,
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        gender: "",
        dob: "",
        passport: "",
        photo: null,
        country: "",
        visatype_id: "",
        purpose: "",
        city: "",
        province_id: "",
        placeOfWork: "",
      },
      passportImage: "",
      visaImage: "",
      rtpcrImage: "",
      profileImage: "",
      provinceSelect: "required",
      vueTel: {
        phone: "",
        props: {
          preferredCountries: ["LA", "CN", "TH", "VN", "US"],
          mode: "international",
          required: true,
          validCharactersOnly: true,
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
    };
  },

  watch: {
    visible(newValue) {
      this.modalVisible = newValue;
    },
  },

  methods: {
    handleModalClose() {
      this.$emit("close");
    },
    moment,
    handleEditProfile() {
      this.submitted = true;
      if (this.user.province_id) {
        this.$validator.validate().then((isValid) => {
          if (isValid) {
            this.confirm();
          }
        });
      }
    },
    confirm() {
      this.$confirm({
        title: "Confirm",
        content: "Are you sure to update?",
        okText: "OK",
        cancelText: "Cancel",
        onOk: async () => {
          const photos = typeof this.user.photo === 'string' ? JSON.parse(this.user.photo) : this.user.photo;
          this.user.photo = {
            passport: this.passportImage ? this.passportImage : photos.photopassport ? photos.photopassport : photos.passport ? photos.passport : null,
            vaccine: this.visaImage ? this.visaImage : photos.photovacince ? photos.photovacince : photos.vaccine ? photos.vaccine : null,
            rtpcr: this.etcImage ? this.etcImage : photos.photortpcr ? photos.photortpcr : photos.rtpcr ? photos.rtpcr : null,
            photoprofile: this.profileImage ? this.profileImage : photos.photoprofile ? photos.photoprofile : null
          };
          this.user.photo = JSON.stringify(this.user.photo);
          await userService.updateProfile(this.user)
            .then(async (res) => {
              this.$message.success("Edit profile successfully");
              this.$emit("change");
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
          this.handleModalClose();
        },
      });
    },
    setUserInfo() {
      this.user.id = this.userInfo?.id || "";
      this.user.firstname = this.userInfo?.firstname || "";
      this.user.lastname = this.userInfo?.lastname || "";
      this.user.phone = this.userInfo?.phone || "";
      this.user.email = this.userInfo?.email || "";
      this.user.gender = this.userInfo?.gender || "";
      this.user.dob = this.userInfo?.dob || "";
      this.user.passport = this.userInfo?.passport || "";
      this.user.photo = this.userInfo?.photo || {};
      this.user.country = this.userInfo?.countrycode || "";
      this.user.visatype_id = this.userInfo?.visatype_id || "";
      this.user.purpose = this.userInfo?.purposeofvisit || "";
      this.user.city = this.userInfo?.city || "";
      this.user.province_id = this.userInfo?.province_id || "";
      this.user.placeOfWork = this.userInfo?.workplace || "";
    }
    ,
    onProvinceSelected(val) {
      this.user.province_id = val;
    },
    onPassportUploaded(passport) {
      this.passportImage = passport;
    },
    onVisaUploaded(visa) {
      this.visaImage = visa;
    },
    onETCUploaded(etc) {
      this.etcImage = etc;
    },
    onProfileUploaded(profile) {
      this.profileImage = profile;
    },
  },
  created() {
    if (this.userInfo) {
      this.setUserInfo();
    }
  },
};
</script>
