<template>
  <div class="clearfix">
    <a-upload
      :fileList="photo"
      :action="uploadTo"
      :headers="authHeader"
      :max-count="2"
      list-type="picture"
      name="file"
      @preview="handlePreview"
      @change="onUploadPhoto"
      :remove="handleRemove"
    >
      <div v-if="photo.length < 1">
        <!-- <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div> -->
        <a-button> <a-icon type="upload" /> {{ uploadText }} </a-button>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="alt-example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { uploadUrl } from '../../utils/helper';
import { lsread } from '../../utils/storage';
import {message} from "ant-design-vue";
const auth = lsread('user')
const authHeader =  {
  'Authorization': `Bearer ${auth.token}`
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  props: {
       uploadText: '',
  },
  name: "WidgetAddPhoto",
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      uploadTo: uploadUrl,
      photo: [],
      authHeader: authHeader,
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    onUploadPhoto({ fileList }) {
      this.photo = fileList;
    if (fileList && fileList[0] && fileList[0].status === 'done') {
    const imageFile = this.photo
    if (imageFile.length > 0) {
      imageFile.forEach((item) => {
        const response = item.response;
        this.$emit("upload", response.url);
      })
    }
  } 
  else if (fileList && fileList[0] && fileList[0].status === 'error') {
    message.error(`${fileList[0].name} file upload failed.`);
  }
  },
  handleRemove(){
    this.$emit("upload", null);
  }
  },
};
</script>
